<template>
  <div class="inner">
    <div class="title">
      <span>{{ $t('index.voting_address') }}</span>
      <span v-if="type == 0">{{ $t('index.voting_status') }}</span>
      <span>{{ $t('index.number_of_voted') }}</span>
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t('index.no_more')"
      @load="onLoad"
      :offset="10"
      :loading-text="$t('tip.loading')"
    >
      <van-cell v-for="(item, index) in voteMsg" :key="index" class="list-msg">
        <div class="msg">
          <div class="one">{{ item.account }}</div>
          <div class="two">
            <span v-if="type == 0 && item.vote_type == '0'"
              >{{ $t('index.abstain') }}
            </span>
            <span v-if="type == 0 && item.vote_type == '1'"
              >{{ $t('index.agree') }}
            </span>

            <span v-if="type == 0 && item.vote_type == '-1'"
              >{{ $t('index.disagree') }}
            </span>
          </div>
          <div class="three">{{ item.vote_number }}</div>
        </div>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
export default {
  props: {
    voteMsg: {
      type: Array,
    },
    type: {
      type: String,
      default: '0',
    },
    total: {
      type: Number,
      default: 0,
    },
    isReset: {
      type: Boolean,
      default: false,
    },
    // finished: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      num: 0,
      loading: false,
      finished: false,
    }
  },
  watch: {
    voteMsg: {
      //深度监听，可监听到对象、数组的变化
      handler(val, oldVal) {
        if (val) {
          this.loading = false
          console.log(oldVal)
          if (val.length == this.total || val.length > this.total) {
            this.finished = true
          } else {
            this.finished = false
          }
        }
      },
      deep: true,
    },
    isReset(newVal, oldVal) {
      if (newVal) {
        this.num = 0
        this.loading = false
        this.finished = false
        this.onLoad()
        console.log(oldVal)
      }
    },
  },
  methods: {
    onLoad() {
      this.loading = true
      this.$emit('fatherMea', this.num)
      setTimeout(() => {
        this.num += 10
      })
    },
  },
}
</script>
<style>
.van-cell {
  padding: 0;
}
</style>
<style lang="less" scoped>
.inner {
  width: 93.3%;
  margin: 22px auto 0 auto;

  .title {
    width: 96%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    font-size: 12px;
    font-weight: 400;
    color: #777789;
  }
  .list-msg {
    width: 100%;
    height: 76px;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.01);
    margin-top: 20px;
    .msg {
      width: 89.71%;
      height: 76px;
      line-height: 76px;
      display: flex;
      // justify-content: space-between;
      margin: 0 auto;
      font-size: 14px;
      font-weight: bold;
      .one {
        width: 47.5%;
      }
      .two {
        width: 15.5%;
      }
      .three {
        width: 37%;
        text-align: right;
      }
    }
  }
}
</style>
